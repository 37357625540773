<template>
    <!--    경매 입찰 레이아웃-->
    <div class="mypage_auction_bid_wrap">
        <!--  리스트 레이아웃-->
        <table class="my_history_table" width="100%" border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="94px"/>
                <col width="275px"/>
                <col width="330px"/>
                <col width="160px"/>
                <col width="135px"/>
                <col width="200px"/>
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{ $t('image') }}</th>
                <th colspan="1">{{ $t('subject') }}</th>
                <th colspan="1">{{ $t('status') }}</th>
                <th colspan="1">{{ $t('a_bid') }}</th>
                <th colspan="1">{{ $t('bidDate') }}</th>
            </tr>
            <tr v-for="(data,index) in itemData" :key="`item${index}`">
                <td>{{ tableIdx(index) }}</td>  <!-- No -->
                <td @click="movePage(data)"><img :src="returnThumbnail(data)" width="78" height="49"/></td>
                <td class="t_subject">{{ data.i_name }}</td>  <!-- 아이템명 -->

                <td>{{ checkBidStatus(data) }}</td>  <!-- 상태 -->


                <td>{{returnCommas(data.ab_bids)}}</td> <!-- 금액 -->

                <td>{{data.created_at}}</td>  <!-- 일시 -->
            </tr>
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemListAuctionLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            this.$router.push(`/item/${data.i_sku}`);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
            }
        },
        checkBidStatus(data) {
            if (data.ab_status === 0) {
                return this.$t('bidFail')
            } else if (data.ab_status === 1) {
                return this.$t('bid')
            } else if(data.ab_status===2){
                return this.$t('s_bid')
            }
        },
        returnCommas(x=0) {
            return util.Number.numFormat(x);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
